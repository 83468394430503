import React, { useEffect, useRef, useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import './paginationAndSearching.scss'
import SelectInputPagination from './selectInputPagination';

const PaginationAndSearching = ({ handleSearch, searchValue, totalCount, pageSize, handleChangeRowsPerPage, onChange, }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSizeChanged, setPageSizeChanged] = useState(pageSize);
    const prevPageSize = useRef(pageSize);

    // Update page function
    const updatePage = (page) => {
        setCurrentPage(page);
        onChange(page);
    }
    useEffect(() => {
        if (searchValue?.trim().length > 2) {
            setCurrentPage(1);
        }
        if (searchValue?.trim().length == 0) {
            setCurrentPage(1);
        }

    }, [searchValue,])


    return (
        <>
            <div className='pagination-main-container mt-2'>

                <div className='pagination-search-container'>
                    {/* <div className="service-input-group input-group">
                        <span className="input-group-text search-input-icon">

                            <i className="bi bi-search" style={{ fontSize: '16px' }}></i>
                        </span>
                        <input
                            type="text"
                            className="form-control search-input"
                            id="val-username1"
                            placeholder="Search..."
                            name="username"
                            value={searchValue}
                            onChange={handleSearch}
                        />
                    </div> */}
                </div>
                &nbsp;

                <div  style={{display:'flex'}}>
                   
                {/* <SelectInputPagination
                            onChange={handleChangeRowsPerPage}
                            defaultValue={pageSize}
                        />    */}
                
                  


                    <Pagination className='m-0 mt-1' >
                        {currentPage > 1 && <Pagination.Item className='m-0 p-0' onClick={() => updatePage(currentPage - 1)}>{'<'}</Pagination.Item>}
                        {[...Array(totalCount)].map((_, i) => {
                            if (i + 1 === currentPage || i + 1 === 1 || i + 1 === totalCount || i + 1 === currentPage - 1 || i + 1 === currentPage + 1) {
                                return (
                                    <Pagination.Item   className='m-0 p-0' key={i + 1} active={i + 1 === currentPage} onClick={() => updatePage(i + 1)}>
                                        {i + 1}
                                    </Pagination.Item>
                                );
                            } else if (i + 1 === currentPage - 2 || i + 1 === currentPage + 2) {
                                return <Pagination.Item  className='m-0 p-0' key={i + 1} onClick={() => { }}>{'...'}</Pagination.Item>;
                            } else {
                                return null;
                            }
                        })}
                        {currentPage < totalCount && <Pagination.Item  className='m-0 p-0' onClick={() => updatePage(currentPage + 1)}>{'>'}</Pagination.Item>}
                    </Pagination>
                </div>





            </div>
        </>
    );
}

export default PaginationAndSearching