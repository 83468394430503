import React, { useContext } from "react";

/// React router dom
import {  Routes, Route, Outlet, BrowserRouter, Navigate  } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";


import ScrollToTop from "./layouts/ScrollToTop";
/// Dashboard
import Home from "./components/Dashboard/Home";

import SortingTable from "./components/table/SortingTable/SortingTable";
import FilteringTable from "./components/table/FilteringTable/FilteringTable";


import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import { ThemeContext } from "../context/ThemeContext";

import { authenticate } from "../feature/authSlice";
import Login from "./pages/Login";
import { useDispatch, useSelector } from "react-redux";


import PreLogin from "./pages/preLogin";
import GetAllTeacherPage from "./pages/Teachers/getAllTeacher";
import SchoolCalendarPage from "./pages/Calendar/schoolCalendar";
import AllStudentPage from "./pages/AllStudent/allStudent";

const Markup = () => {


  const {authorized,permissionArr} = useSelector(state => state.auth)
  const dispatch = useDispatch();

  if (localStorage.getItem("Ok") ) {
    dispatch(authenticate());
  }


 


  return (
    <>
    <BrowserRouter>
        <Routes>
            <Route path='page-lock-screen' element= {<LockScreen />} />
            <Route path='page-error-400' element={<Error400/>} />
            <Route path='page-error-403' element={<Error403/>} />
            <Route path='page-error-404' element={<Error404/>} />
            <Route path='page-error-500' element={<Error500/>} />
            <Route path='page-error-503' element={<Error503/>} />
          { authorized ? (
          
          <Route  element={<MainLayout />} > 
          <Route path ="*" element={<Navigate to ="/"/>} />
          <Route path='/' element={<Home />} />
              <Route path='dashboard' element={<Home />} />
              <Route path='teachers' element={<GetAllTeacherPage />} />
              <Route path='students' element={<AllStudentPage />} />
              <Route path='calendar' element={<SchoolCalendarPage />} />
              <Route path='*' element={<Home />} />
            </Route> ):( <>
            <Route path='*' element={<PreLogin />} />
      <Route path='/login' element={<Login />} />
    
            </>)
      
  }
        </Routes>
        <ScrollToTop />
        </BrowserRouter>
    </>
  );
};


function MainLayout(){
  const { menuToggle } = useContext(ThemeContext);
  return (
    <div id="main-wrapper" className={`show ${ menuToggle ? "menu-toggle" : ""}`}>  
      <Nav />
      <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>
          <div className="container-fluid">
            <Outlet />                
          </div>
      </div>
      {/* <Footer /> */}
    </div>
  )

};

export default Markup;
