import React from 'react';
import { Swiper, SwiperSlide,  } from "swiper/react";
import { Link } from "react-router-dom";
import { Autoplay } from "swiper";
import { Pagination } from 'swiper';
import "swiper/css";

import pic1 from '../../../icons/myIcons/myloginImg.png';
import pic2 from '../../../images/img2.png';
const swiperData = [
    {image: pic1,
        text:true
    }
];

const Slider = () =>{
    return(
        <>
            <Swiper className="swiper-container mySwiper-4"						
				speed= {1500}
				slidesPerView= {1}
				spaceBetween= {20}
			
				autoplay= {{
				   delay: 2000,
				}}
				modules={[ Autoplay,Pagination ]}
				breakpoints = {{
					600: {
						slidesPerView: 1,
						spaceBetween: 10,
					  },
					  768: {
						slidesPerView: 1,
						spaceBetween: 10,
					  },
					  1024: {
						slidesPerView: 1,
						spaceBetween: 20,
					  },
					  1200: {
						slidesPerView: 1,
						spaceBetween: 20,
					  },
					  1600: {
						slidesPerView: 1,
						spaceBetween: 20,
					  },
					  1920: {
						slidesPerView: 1,
						spaceBetween: 20,
					  },
				}}
			>	
				{swiperData.map((d,i)=>(
					<SwiperSlide key={i}>
                        <div className="card mb-0">
                            <div className="card-body pb-2 pt-3">
                                <div className="text-center pop-cousin">
                                    <img src={d.image} alt="" style={{height:'500px'}}/>
                                  { d.text &&<>
                                    <h3>
                                        Facility Management System
                                    </h3>
                                    <Link to={"#"}><h6>Facility Management System – Streamline, Simplify, and Save ! </h6></Link></>}
                                </div>
                            </div>
                        </div>
					</SwiperSlide>
				))}				
			</Swiper>
        </>
    )
}
export default Slider;