import { toast } from "react-toastify";

export   function formatDate(dateString) {

    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    let hours = date.getHours();
    let minutes = date.getMinutes();

    // Adding leading zero if hours or minutes is less than 10
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    // return day + "-" + month + "-" + year + " " + hours + ":" + minutes;

    return day + "-" + month + "-" + year
  }


  export const   currencyFormat =(value) =>{

    const formattedNumber = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(value);
    return formattedNumber;
  }

  export function formatDateTime(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    let hours = date.getHours();
    let minutes = date.getMinutes();

    // Adding leading zero if hours or minutes is less than 10
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    // Returning day, month, year, and time
    return day + "-" + month + "-" + year + " " + hours + ":" + minutes;
}

export   const notifyTopRightCommon = (e, s) => {
  if (s == "s") {
    toast.success(e, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,

    });
  } else {
    toast.error(e, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,

    });
  }
};