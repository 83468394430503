import React, { useState } from 'react'
import { Button } from 'react-bootstrap'





export default function PrimaryButton({ buttonName, onClick, type, loadingMessage, isLoading, style, iconClassName, needIcon, className = false }) {


    return (

        <Button
            onClick={onClick}
            variant="primary"
            type={type}
            disabled={isLoading}
            style={{
                ...style,
                borderRadius: '4px',
            }}
            className={className}
        >
            <div className='d-flex'>

                <div className='col-2'>

                </div>
                <div className='col-8'>
                    {isLoading ? loadingMessage : buttonName}
                </div>
                <div className='col-2 d-flex justify-content-end align-items-center'>
                    {needIcon && <i className={iconClassName}></i>}
                </div>
            </div>


        </Button>



    )
}

