


import React, { useContext, useEffect, useReducer, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Dropdown, Modal, Nav, Tab } from "react-bootstrap";
import 'react-datepicker/dist/react-datepicker.css';


import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import InputField from "../../components/Fields/inputFields";
import AllStudentTable from "./allStudentTable";
import { getAllStudentListApi } from "../../../service/Student/getAllStudentList";
import SelectInput from "../../components/Fields/selectField";
import { registerStudentApi } from "../../../service/Student/registerStudent";
import { notifyTopRightCommon } from "../../../function/commonFunction";
import { classOptions } from "../../../function/validation";


const AllStudentPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  


  const[propertiesList,setPropertiesList] = useState([])

  const [userArr,setUserArr] = React.useState([])

  const [addStudentModal,setAddStudentModal] =useState(false)
  const [ isLoading,setIsLoading] = useState(false)
  const [totalStudent,setTotalStudent] = useState(0)
  const [studentFormData, setStudentFormData] = useState({
    name: "",
    lastName:"",
    rollNumber: "",
    regNumber: "",
    class: "",
    section: "",
    email: "",
    phoneNumber: ""
});

const [studentClass, setStudentClass] = useState('');



useEffect(()=>{
    onloadData()
},[])

function onloadData (){
    var data = {
        type:1,
page:1,
size:100
    }

    dispatch(getAllStudentListApi(data,(res)=>{
        setTotalStudent(res.data.totalRecords)
        setUserArr(res.data.result)
    },()=>{}))
}

 
  const pendingColumns = [
 
    {
      Header: "Name",
      Footer: "",
      accessor: "firstName",
    },
    {
        Header: "Roll No.",
        Footer: "",
        accessor: "rollNo",
      },
   
    {
      Header: "Class",
      Footer: "",
      accessor: "class",
    },
    {
        Header: "Section",
        Footer: "",
        accessor: "section",
      },
  
    {
      Header: "Phonenumber",
      Footer: "",
      accessor: "phoneNumber",
    },
  
   
   
    
    
    
   






  ];

  function handleAddStudentModal (){
setAddStudentModal(true)
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStudentFormData(prevState => ({
        ...prevState,
        [name]: value
    }));
};

const handleAddStudent = () => {
  // Check if all required fields are filled
  if (!studentFormData.name || !studentFormData.rollNumber || !studentFormData.regNumber ||
      !studentClass || !studentFormData.section || !studentFormData.email || !studentFormData.phoneNumber) {
      toast.error("Please fill in all fields.");
      return;
  }
  setIsLoading(true);

  var data ={
    schoolId: "school_1",
    email: studentFormData.email,
    phoneNumber:studentFormData.phoneNumber ,
    firstName:studentFormData.name ,
    lastName:studentFormData.lastName ,
    class:studentClass ,
    section:studentFormData.section ,
    role:1 ,
    enrollmentDate:new Date().toISOString().split("T")[0] ,
    password:"Test@123",
    regNo:studentFormData.regNumber,
    rollNo:studentFormData.rollNumber
  }
  console.log(data,"kk")
  dispatch(registerStudentApi(data,()=>{
  setIsLoading(false);
  onloadData()
  setAddStudentModal(false)

    notifyTopRightCommon("Student Added to your record","s")
  },(err)=>{
    console.log(err)
    setIsLoading(false);
    notifyTopRightCommon("Server Down","e")
  }))
  // Simulate API call
  // setTimeout(() => {
  //     // Here you would dispatch your add student API action
  //     console.log("Student added:", studentFormData);
  //     toast.success("Student added successfully.");
  //     setIsLoading(false);
  //     setAddStudentModal(false);
  // }, 1500);
};

const handleClassChange = (e) => {
  setStudentClass(e.target.value);
};

  return (
    <>
      <ToastContainer />
      {/* {  toaster && <AutohideToaster action={actionMessage} message={message} handleFucntion={()=>{setToaster(false)}}/>} */}
      <div className="row d-flex justify-content-between">
        {/* <BalanceCardSlider /> */}
        <div className="row">
        <div className="col-xxl-12">
          <div className="card">
          

            <Tab.Container defaultActiveKey="Pending">
              <div className="card-header border-0 pb-2 flex-wrap">
                <h4 className="heading me-2">Student's List</h4>
                <button
            className="btn "
            style={{ background: "rgba(23, 90, 169, 0.78)", color: "white" }}
            onClick={handleAddStudentModal}
          >
           Add New Student +
          </button>
              </div>
              <div className="card-body pt-2">
              <AllStudentTable
                      column={pendingColumns}
                      rowsArr={userArr}
                  

                      totalCount={totalStudent}
                    />
              </div>
            </Tab.Container>
          </div>
        </div>
      </div>
      </div>


   



      <Modal className="fade" show={addStudentModal} centered>
        <Modal.Header>
          <Modal.Title> Add Student</Modal.Title>
          <Button
            onClick={() => setAddStudentModal(false)}
            variant=""
            className="btn-close"
          ></Button>
        </Modal.Header>
        <Modal.Body>
                
        <InputField labelName={"Name"} name="name" value={studentFormData.name} onChange={handleInputChange} />
        <InputField labelName={"Last name"} name="lastName" value={studentFormData.lastName} onChange={handleInputChange} />
        <SelectInput
                        labelName="Class"
                        options={classOptions}
                        onChange={handleClassChange}
                        defaultValue={studentClass}
                        extraOption="Select a class"
                    />
<InputField labelName={"Section"} name="section" value={studentFormData.section} onChange={handleInputChange} />

<InputField labelName={"Roll Number"} name="rollNumber" value={studentFormData.rollNumber} onChange={handleInputChange} />
<InputField labelName={"Reg Number"} name="regNumber" value={studentFormData.regNumber} onChange={handleInputChange} />
<InputField labelName={"Email"} name="email" value={studentFormData.email} onChange={handleInputChange} />
<InputField labelName={"Phone Number"} name="phoneNumber" value={studentFormData.phoneNumber} onChange={handleInputChange} />

        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setAddStudentModal(false)}
            variant="danger light"
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleAddStudent}
          >
           {isLoading ? "Adding": "Add"}
          </Button>
        </Modal.Footer>
      </Modal>

    

     
    
    </>
  );
};
export default AllStudentPage;
