import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";


import logo from "../../icons/myIcons/logo.png";

import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import InputField from "../components/Fields/inputFields";

import PrimaryButton from "../components/Fields/primaryButton";
import Slider from "../components/swiper/swiper";
import { validateSchoolApi } from "../../service/authApi/validateSchool";

function PreLogin(props) {
  const dispatch = useDispatch();

 

  const notifyTopRight = (e, s) => {
    if (s == "s") {
      toast.success(e, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,

      });
    } else {
      toast.error(e, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,

      });
    }
  };

  
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loadingActive, setLoadingActive] = useState(false);
const[apiLoading,setApiLoading] = useState(false)


  function validateEntry(e) {
    
    setApiLoading(true)
    var data = {
      schoolId:email
    }
   
   dispatch(validateSchoolApi(data,()=>{
    localStorage.setItem("schoolId",email)
    setApiLoading(false)
    navigate("/login")},(err)=>{
      notifyTopRight("Invalid School Id","e")
      setApiLoading(false)
      console.log("iok",err)}))

  }


 

 
  

  return (

  <>
  <ToastContainer/>
    <div className=" " style={{ background: '#f1f1ff' }}>

<div className="col-xl-12 " style={{ height: '100dvh', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
  <div className=" border-0 d-flex justify-content-center">
    <div className=" card login-bx" style={{ width: '80%' }}>
      <div className="row custome-row">
        <div
          className="col-xl-6 col-md-6  text-center d-flex justify-content-center align-items-center "
          style={{ borderRight: '1px solid lightgray' }}
        >
          {/* <img src={logo} alt="" style={{ width: '250px' }} /> */}
          <Slider />
        </div>
        <div
          className="col-xl-6 col-md-6  d-flex cutome-col align-items-center justify-content-center overflow-auto"
        >

          <div className="sign-in-your mt-3 " style={{ width: '90%' }}>
            <div className="row d-flex justify-content-center">
              <img
                src={logo}
                style={{ width: "150px", cursor: "pointer" }}
                alt=""
              />
            </div>
            <div className="row mt-1">
              <p className="login-subtitle mt-3" style={{ textAlign: 'center' }}>
                {/* Process your invoices */}
              </p>
            </div>

            <div className="row mt-4">
              <span className="login-title" style={{ textAlign: 'center', fontWeight: '600' }}>
               Enter a valid school code
              </span>
            </div>

         <div className="row mt-4">
        

                <InputField labelName={"School Code"} onChange={(e) => {
                 
                  setEmail(e.target.value);
                }} value={email}
                  // errorOccured={emailError}
                  errorMeesage={"Please enter valid email"}
                />
                
            

           
            </div>












            <PrimaryButton
              buttonName={"Proceed"}
              type={"submit"}
              isLoading={apiLoading}
              loadingMessage={"Loading..."}
              style={{
                width: "100%",


              }}
              onClick={() => { validateEntry() }}
            />
          
            <div className="row d-flex justify-content-center " >



              {/* Apple Signin custom Button for login */}
              <div className="col-12 d-flex justify-content-center">
                <div className="buttoncover">

                </div>
              </div>
            </div>

            
           
          </div>

        </div>
      </div>
    </div>
  </div>
</div>



</div>
</>

  );
}

export default PreLogin;
