import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Card, Button, Form, Modal } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useDispatch } from "react-redux";
import { getAllEventsApi } from "../../../service/Calendar/getEvent";
import { addEventsApi } from "../../../service/Calendar/addEvent";
import InputField from "../../components/Fields/inputFields";
import TextArea from "../../components/Fields/textarea";
import { deleteEventsApi } from "../../../service/Calendar/deleteEvent";
import { notifyTopRightCommon } from "../../../function/commonFunction";
import { editEventsApi } from "../../../service/Calendar/editEvent";
import './SchoolCalendarData.css';
const MySwal = withReactContent(Swal);

const SchoolCalendarData = () => {
    const dispatch = useDispatch();
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [editModalStatus, setEditModalStatus] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [title, setTitle] = useState("");
    const [descrip, setDescription] = useState("");
    const [deletingApi, setDeletingApi] = useState(false);
    const [apiLoading, setApiLoading] = useState(false);
    const[currentMonthOnCalendar,setCurrentMonthOfCalendar] = useState(new Date().toISOString().split("T")[0])
    const [events] = useState([
        { title: "Event 1", id: "1" },
        { title: "Event 2", id: "2" },
        { title: "Event 3", id: "3" },
        { title: "Event 4", id: "4" },
        { title: "Event 5", id: "5" },
        { title: "Event 6", id: "6" },
    ]);

    const [newEvent, setNewEvent] = useState({
        title: "",
        description: "",
        start: getFormattedDateTime(),
    });

    const [editEvent, setEditEvent] = useState({
        id: "",
        title: "",
        start: "",
        description: "",
    });

    const calendarRef = useRef(null);

    function getFormattedDateTime() {
        const now = new Date();
        now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
        return now.toISOString().slice(0, 16);
    }

    useEffect(() => {
        let draggableEl = document.getElementById("external-events");
        new Draggable(draggableEl, {
            itemSelector: ".fc-event",
            eventData: function (eventEl) {
                let title = eventEl.getAttribute("title");
                let id = eventEl.getAttribute("data");
                return {
                    title: title,
                    id: id,
                };
            },
        });
    }, []);

    const eventClick = (eventClick) => {

        const event = eventClick.event;
        console.log(event,"Click event")
        setEditEvent({
            id: event.id,
            title: event.title,
            start: new Date(event.start).toISOString().slice(0, 16),
            description: event.extendedProps.description,
        });
        setEditModalStatus(true);
        setTitle(event.title);
        setDescription(event.extendedProps.description);
        setStartDate(new Date(event.start).toISOString().slice(0, 16));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewEvent((prevNewEvent) => ({
            ...prevNewEvent,
            [name]: value,
        }));
    };

    const addEvent = (e) => {
        setApiLoading(true);
        e.preventDefault();
        const { title, start,description } = newEvent;
        if (title && start) {
            var data = {
                title: title,

                startDate: start.split("T")[0],
                category:[1,2,3],
                description:description
            };

            dispatch(
                addEventsApi(
                    data,
                    () => {
                        notifyTopRightCommon("Event Added Successfully","s")
                        setApiLoading(false);
                        onLoadData()
                        setCalendarEvents((prevCalendarEvents) => [
                            ...prevCalendarEvents,
                            { title, start: new Date(start), id: Date.now().toString() },
                        ]);
                        setNewEvent({ title: "", start: getFormattedDateTime(), description: "" });
                    },
                    () => {
                        setApiLoading(false);
                    }
                )
            );
        } else {
            notifyTopRightCommon("Invalid Inputs");
            setDeletingApi(false);
        }
    };

    useEffect(() => {
        onLoadData();
    }, [currentMonthOnCalendar]);

    function onLoadData() {
        var data = {
            date:currentMonthOnCalendar
        };
        dispatch(
            getAllEventsApi(
                data,
                (res) => {
                    setCalendarEvents(res.data);
                },
                () => {}
            )
        );
    }

    function deleteEventFn() {
        setDeletingApi(true);
        var data = {
            id: editEvent.id,
        };
        dispatch(
            deleteEventsApi(
                data,
                () => {
                    notifyTopRightCommon("Event Deleted Successfully", "s");
                    setDeletingApi(false);
                    setCalendarEvents((prevCalendarEvents) =>
                        prevCalendarEvents.filter((e) => e.id !== editEvent.id)
                    );
                    setEditModalStatus(false);
                },
                () => {
                    notifyTopRightCommon("Invalid Operation", "e");
                    setDeletingApi(false);
                }
            )
        );
    }

    function editEventFn(){
        setApiLoading(true)
        var data={
            id:editEvent.id,
            title:title,
             description: descrip,
           startDate: startDate,
           category: [1,3,2]
        }

    dispatch(editEventsApi(data,()=>{
        onLoadData()
        setEditModalStatus(false)
        notifyTopRightCommon("Event Updated Successfully","s")
        setApiLoading(false)
    },()=>{
        notifyTopRightCommon("Server Down","e")
        setApiLoading(false)
    }))
    }
    const handleDatesSet = (dateInfo) => {
        console.log(dateInfo,"kk")
        // Extract the start date and format it to ISO string split by "T" to get the date part
        const middleOfRange = new Date((dateInfo.start.getTime() + dateInfo.end.getTime()) / 2);
        const newCurrentMonth = middleOfRange.toISOString().split("T")[0];
    
        setCurrentMonthOfCalendar(newCurrentMonth);
    };

    return (
        <div className="animated fadeIn demo-app" style={{ zoom: "0.9" }}>
            <Row>
                <Col lg={3}>
                    <Card>
                        <Card.Body>
                            <h4 className="text-black fs-20 mb-3">Add New Event</h4>
                            <Form onSubmit={addEvent}>
                                <InputField
                                    labelName={"Event Title"}
                                    name="title"
                                    value={newEvent.title}
                                    onChange={handleInputChange}
                                />
                                <InputField
                                    labelName={"Event Description"}
                                    name="description"
                                    value={newEvent.description}
                                    onChange={handleInputChange}
                                />
                                <InputField
                                    labelName={"Date"}
                                    type={"datetime-local"}
                                    name="start"
                                    value={newEvent.start}
                                    onChange={handleInputChange}
                                />
                                <Button variant="primary" type="submit" className="mt-2" disabled={apiLoading}>
                                   {apiLoading ? "Adding": " Add Event"}
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                    <Card className="mt-4">
                        <div className="card-header border-0 pb-0">
                            <h4 className="text-black fs-20 mb-0">Events</h4>
                        </div>
                        <Card.Body>
                            <div id="external-events">
                                {events.map((event) => (
                                    <div
                                        className="fc-event mt-0 ms-0 mb-2 btn btn-block btn-primary"
                                        title={event.title}
                                        data={event.id}
                                        key={event.id}
                                    >
                                        {event.title}
                                    </div>
                                ))}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>

                <Col lg={9}>
                    <Card>
                        <Card.Body>
                            <div className="demo-app-calendar" id="mycalendartest">
                                <FullCalendar
                                    defaultView="dayGridMonth"
                                    header={{
                                        left: "prev,next today",
                                        center: "title",
                                        right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                                    }}
                                    rerenderDelay={10}
                                    eventDurationEditable={false}
                                    editable={false}
                                    droppable={false}
                                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                    ref={calendarRef}
                                    weekends={true}
                                    events={calendarEvents.map((event) => ({
                                        id: event.id,
                                        title: event.title,
                                        start: new Date(event.startDate),
                                        description: event.description,
                                        category: event.category,
                                    }))}
                                    eventClick={eventClick}
                                    themeSystem="bootstrap" 
                            
                              datesSet={handleDatesSet}
                                />
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Modal className="fade" show={editModalStatus} centered>
                <Modal.Header>
                    <Modal.Title> Update Event</Modal.Title>
                    <Button
                        onClick={() => {
                            setEditModalStatus(false);
                        }}
                        variant=""
                        className="btn-close"
                    ></Button>
                </Modal.Header>
                <Modal.Body>
                    <InputField labelName={"Title"} value={title} onChange={(e) => { setTitle(e.target.value) }} />
                    <InputField labelName={"Date"} type={"datetime-local"} value={startDate} onChange={(e) => { setStartDate(e.target.value) }} />
                    <TextArea labelName={"Description"} value={descrip} onChange={(e) => { setDescription(e.target.value) }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger light" onClick={deleteEventFn}>
                        {deletingApi ? "Deleting" : "Delete Event"}
                    </Button>
                    <Button variant="primary" onClick={() => {
                        editEventFn()
                        }}>
                       {apiLoading ? "Updating":"Update"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default SchoolCalendarData;
