import { apiConstants } from "../apiConstant"
import {  serviceCall, serviceCallAuth } from "../serviceCall"

export const getAllEventsApi = (data, callback, errorCallBack) => {
  return async (dispatch) => {
    serviceCall({
      url: apiConstants.CALENDAR.GET_EVENT,
      method: 'get',
      data: data,
    })
      .then((response) => {
        callback && callback(response)
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error.response)
      })
  }
}