import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";


import logo from "../../icons/myIcons/logo.png";
import bg6 from "../../images/background/bg6.jpg";
import Select from "react-select";
import { authenticate, setUserDetails } from "../../feature/authSlice";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import InputField from "../components/Fields/inputFields";
import { Button, Modal, Nav, Tab } from "react-bootstrap";
import { emailValidation, passwordValidation, phoneOnChange, phoneValidation } from "../../function/validation";
import PrimaryButton from "../components/Fields/primaryButton";
import Slider from "../components/swiper/swiper";
import PasswordField from "../components/Fields/passwordField";
import OtpModal from "../components/OtpModal/OtpModal";
import { adminLoginApi } from "../../service/authApi/adminLogin";

function Login(props) {
  const dispatch = useDispatch();



  const notifyTopRight = (e, s) => {
    if (s == "s") {
      toast.success(e, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,

      });
    } else {
      toast.error(e, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,

      });
    }
  };



  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loadingActive, setLoadingActive] = useState(false);

  function validateEntry(e) {

    onLogin()






  }
  function onLogin(e) {
    setLoadingActive(true)
    var data = {
      schoolId: localStorage.getItem("schoolId"),
      regNo: email,
      password: password,
      deviceToken:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZWdObyI6IkFETTAwMSIsInVzZXIiOjMsInNjaG9vbElkIjoic2Nob29sXzEiLCJpYXQiOjE3MjE0OTc0MTEsImV4cCI6MTcyMjEwMjIxMX0.pbEGzPfzhqIP-qznr_2U4taN0b8o2wvNQuIcxz3rD0c"
    }

    dispatch(adminLoginApi(data, (res) => {
      localStorage.setItem("access_token",res.data.token)
    dispatch(authenticate())
      dispatch(setUserDetails(res.data.user))
      setLoadingActive(false)
    }, () => {
      notifyTopRight("Invalid credentials", "e")
      setLoadingActive(false)
    }))
    
  }





  return (

    <>
      <ToastContainer />
      <div className=" " style={{ background: '#f1f1ff' }}>

        <div className="col-xl-12 " style={{ height: '100dvh', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <div className=" border-0 d-flex justify-content-center">
            <div className=" card login-bx" style={{ width: '80%' }}>
              <div className="row custome-row">
                <div
                  className="col-xl-6 col-md-6  text-center d-flex justify-content-center align-items-center "
                  style={{ borderRight: '1px solid lightgray' }}
                >
                  {/* <img src={logo} alt="" style={{ width: '250px' }} /> */}
                  <Slider />
                </div>
                <div
                  className="col-xl-6 col-md-6  d-flex cutome-col align-items-center justify-content-center overflow-auto"
                >

                  <div className="sign-in-your mt-3 " style={{ width: '90%' }}>
                    <div className="row d-flex justify-content-center">
                      <img
                        src={logo}
                        style={{ width: "150px", cursor: "pointer" }}
                        alt=""
                      />
                    </div>
                    <div className="row mt-1">
                      <p className="login-subtitle mt-3" style={{ textAlign: 'center' }}>
                        {/* Process your invoices */}
                      </p>
                    </div>

                    <div className="row mt-4">
                      <span className="login-title" style={{ textAlign: 'center', fontWeight: '600' }}>
                        Enter a valid school login credentials
                      </span>
                    </div>

                    <div className="row mt-4">
                      <form onSubmit={validateEntry}>

                        <InputField labelName={"Username"} onChange={(e) => {
                          setEmail(e.target.value);
                        }} value={email}
                          // errorOccured={emailError}
                          errorMeesage={"Please enter valid username"}
                        />

                        <PasswordField labelName={"Password"} onChange={(e) => {

                          setPassword(e.target.value);
                        }} inputValue={password} errorMeesage={"Please enter valid password "} />


                      </form>
                    </div>












                    <div className="form-group text-left d-flex justify-content-end mt-2">

                      <p style={{ color: "#9568FF", cursor: 'pointer' }} onClick={() => { navigate("/forgotPassword") }}>
                        Forgot Password ?
                      </p>
                    </div>

                    <PrimaryButton
                      buttonName={"Login"}
                      type={"submit"}
                      isLoading={loadingActive}
                      loadingMessage={"Loading..."}
                      style={{
                        width: "100%",


                      }}
                      onClick={() => { validateEntry() }}
                    />

                    <div className="row d-flex justify-content-center " >



                      {/* Apple Signin custom Button for login */}
                      <div className="col-12 d-flex justify-content-center">
                        <div className="buttoncover">

                        </div>
                      </div>
                    </div>



                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </>


    // <div className="page-wraper">
    //   <ToastContainer bodyStyle={{ fontSize: "14px" }} />
    //   <div className="browse-job login-style3">
    //     <div className="bg-img-fix overflow-hidden" style={{ height: "100vh", background: '#fff url(' + bg6 + ')' }}>
    //       <div className="row gx-0 d-flex justify-content-center">
    //         <div className="col-xl-5 col-lg-6 col-md-6 col-sm-12 vh-100 bg-white d-flex align-items-center">
    //           <div

    //             className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
    //             style={{ width: '100%' }}
    //           >
    //             <div
    //               id="mCSB_1_container"
    //               className="mCSB_container"
    //               style={{
    //                 position: "relative",
    //                 top: "0",
    //                 left: "0",
    //                 dir: "ltr",
    //               }}
    //             >
    //               <div className="login-form style-2">
    //                 <div className="card-body">
    //                   <div className="logo-header">
    //                     <Link to={"#"} className="logo">
    //                       <img
    //                         src={logo}
    //                         alt=""
    //                         className="width-170 mCS_img_loaded"
    //                       />
    //                     </Link>
    //                   </div>
    //                   <div className="nav nav-tabs border-bottom-0">
    //                     <div className="tab-content w-100" id="nav-tabContent">
    //                       <div
    //                         className="tab-pane fade active show"
    //                         id="nav-personal"
    //                       >
    //                         {props.errorMessage && (
    //                           <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
    //                             {props.errorMessage}
    //                           </div>
    //                         )}
    //                         {props.successMessage && (
    //                           <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
    //                             {props.successMessage}
    //                           </div>
    //                         )}

    //                         <form className=" dz-form pb-3" onSubmit={validateEntry}>
    //                           <div>
    //                             <Tab.Container defaultActiveKey={"email"}>
    //                               <div className="card-header border-0 pb-2 flex-wrap">
    //                                 <h3 className="form-title m-t0">Login using</h3>
    //                                 <nav>
    //                                   <Nav as="div" className="order nav nav-tabs" role="tablist">
    //                                     <Nav.Link

    //                                       eventKey="email"
    //                                       onClick={() => {
    //                                         setEmail("")
    //                                         setTabOpen(1) 
    //                                       setEmail("")
    //                                       setPhone("")
    //                                       setPassword("")
    //                                       setPasswordError(false)
    //                                       setEmailError(false)
    //                                       setPhoneError(false)
    //                                       }}
    //                                     >
    //                                       Email
    //                                     </Nav.Link>
    //                                     <Nav.Link

    //                                       eventKey="mobile"
    //                                       onClick={() => { 
    //                                         setEmail("")
    //                                         setTabOpen(2)
    //                                         setEmail("")
    //                                         setPhone("")
    //                                         setPassword("")
    //                                         setPasswordError(false)
    //                                         setEmailError(false)
    //                                         setPhoneError(false)
    //                                       }}
    //                                     >
    //                                       Mobile
    //                                     </Nav.Link>

    //                                   </Nav>
    //                                 </nav>
    //                               </div>
    //                               <div className="card-body pt-2">
    //                                 <Tab.Content>
    //                                   <Tab.Pane eventKey="email">
    //                                     <div className="form-group mb-4 mt-3">
    //                                       <InputField labelName={"Email"} onChange={(e) => {
    //                                         setMessageErrorStatus(false);
    //                                         errors.email = false;
    //                                         setEmail(e.target.value);
    //                                         setEmailError(false)
    //                                       }} value={email}
    //                                         errorOccured={emailError}
    //                                         errorMeesage={"Please enter valid email"}
    //                                       />
    //                                     </div>

    //                                     <div className="form-group mb-4">
    //                                       <InputField labelName={"Password"} onChange={(e) => {
    //                                         setMessageErrorStatus(false);

    //                                         errors.password = false;
    //                                         setPassword(e.target.value);
    //                                         setPasswordError(false)
    //                                       }} value={password} errorOccured={passwordError} errorMeesage={"Please enter valid password "} />


    //                                     </div>
    //                                     <div className="form-group mb-4">
    //                                       <Select
    //                                         defaultValue={selectedOption}
    //                                         onChange={(e) => {
    //                                           setSelectedOption(e);
    //                                         }}
    //                                         placeholder="Select Organization"
    //                                         options={options}
    //                                         style={{
    //                                           lineHeight: "40px",
    //                                           color: "#7e7e7e",
    //                                           paddingLeft: " 15px",
    //                                         }}
    //                                       />
    //                                       {/* {/* <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} /> */}
    //                                       {/* {errors.email && <div className="text-danger fs-12">{errors.email}</div>}  */}
    //                                     </div>

    //                                     <div className="form-group text-left d-flex justify-content-between mt-2">
    //                                       <span className="form-check d-inline-block ms-2">
    //                                         <input
    //                                           type="checkbox"
    //                                           className="form-check-input"
    //                                           id="check1"
    //                                           name="example1"
    //                                         />
    //                                         <label
    //                                           className="form-check-label"
    //                                           htmlFor="check1"
    //                                         >
    //                                           Remember me
    //                                         </label>
    //                                       </span>
    //                                       <p style={{ color: "#9568FF" }}>
    //                                         Forget Password ?
    //                                       </p>
    //                                     </div>

    //                                     <div className="form-group text-left  mt-2">
    //                                       <button
    //                                         type="submit"
    //                                         className="btn btn-primary dz-xs-flex m-r5"
    //                                         disabled={loadingActive}
    //                                         style={{ width: '100%' }}
    //                                       >
    //                                         {loadingActive ? "Logging In..." : "Login"}
    //                                       </button>
    //                                     </div>
    //                                   </Tab.Pane>
    //                                   <Tab.Pane eventKey="mobile">
    //                                     <div className="form-group mb-4  mt-3">

    //                                       <InputField labelName={"Mobile"} onChange={(e) => {
    //                                     phoneOnChange(e.target.value)  &&  setPhone(e.target.value) 
    //                                     setPhone(e.target.value)
    //                                         setPhoneError(false)
    //                                       }} value={phone} 
    //                                       errorMeesage={"Please enter valid mobile"}
    //                                       errorOccured={phoneError}

    //                                       />


    //                                     </div>


    //                                     <div className="form-group mb-4">
    //                                       <Select
    //                                         defaultValue={selectedOption}
    //                                         onChange={(e) => {
    //                                           setSelectedOption(e);
    //                                         }}
    //                                         placeholder="Select Organization"
    //                                         options={options}
    //                                         style={{
    //                                           lineHeight: "40px",
    //                                           color: "#7e7e7e",
    //                                           paddingLeft: " 15px",
    //                                         }}
    //                                       />

    //                                     </div>

    //                                     <div className="form-group text-left d-flex justify-content-between mt-2">
    //                                       <span className="form-check d-inline-block ms-2">
    //                                         <input
    //                                           type="checkbox"
    //                                           className="form-check-input"
    //                                           id="check1"
    //                                           name="example1"
    //                                         />
    //                                         <label
    //                                           className="form-check-label"
    //                                           htmlFor="check1"
    //                                         >
    //                                           Remember me
    //                                         </label>
    //                                       </span>
    //                                       <p style={{ color: "#9568FF" }}>
    //                                         Forget Password ?
    //                                       </p>
    //                                     </div>

    //                                     <div className="form-group text-left  mt-2">
    //                                       <button
    //                                         type="submit"
    //                                         className="btn btn-primary dz-xs-flex m-r5"
    //                                         disabled={loadingActive}
    //                                         style={{ width: '100%' }}
    //                                       >
    //                                         {loadingActive ? "Logging In..." : "Login"}
    //                                       </button>
    //                                     </div>
    //                                   </Tab.Pane>

    //                                 </Tab.Content>
    //                               </div>
    //                             </Tab.Container>
    //                           </div>
    //                         </form>
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>


    // </div>
  );
}

export default Login;
