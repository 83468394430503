import React, { useEffect, useRef, useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap';

const SelectInput = ({
    onChange,
    labelName,
    options,
    extraOption,
    defaultValue,
    disabled,
    errorOccured,
    errorMessage
}) => {
    const [activated, setActivated] = useState(false);
    const labelRef = useRef();
    const [coverWidth, setCoverWidth] = useState(0);

    useEffect(() => {
        setCoverWidth(
            labelRef.current ? labelRef.current.getBoundingClientRect().width + 9 : 0
        );
        if (options?.length > 0) {
            setActivated(true);
        }
    }, [labelRef]);

    return (
        // <div className="mb-3">

        //     <label className="mb-1"><strong>{labelName} </strong></label>
        //     <div>
        //         <select
        //             style={{ border: '2px solid rgba(107, 107, 107, 0.12)', cursor: 'pointer' }}
        //             className={`custom-select join-as-style ${errorOccured ? 'border-danger' : ''}`}
        //             onChange={onChange}
        //             defaultValue={defaultValue}
        //             disabled={disabled}
        //         >
        //             <option value={''} disabled>Select competency</option>
        //             {options?.map((option) => (
        //                 <option value={option.competency_id}>{option.competency_name}</option>
        //             ))}
        //         </select>
        //         {errorOccured && <span style={{ color: 'red', fontSize: '10px', }}>{errorMessage}</span>}

        //     </div>
        // </div>
        <>
            <div className="col-12 mb-4">
                <InputGroup
                    className={errorOccured ? "" : "mb-2"}
                    onFocus={() => {
                        setActivated(true);
                    }}
                >
                    {" "}
                    <>
                        <Form.Group controlId="exampleForm.SelectCustom" className="col-12 border-danger">
                            {/* <Form.Label>Select Options</Form.Label> */}
                            <Form.Label
                                className={`${activated || defaultValue?.length > 0
                                    ? "textfield-label-activated"
                                    : "textfield-label-unactivated"
                                    } textfield-label`}
                                ref={labelRef}
                                onClick={() => {
                                    setActivated(true);
                                }}>
                                <strong className={`${errorOccured && 'text-danger'}`}> {labelName}</strong>
                            </Form.Label>
                            <div
                                className={`${activated || defaultValue?.length > 0
                                    ? "textfield-label-cover-activated"
                                    : "textfield-label-cover-unactivated"
                                    } textfield-label-cover `}
                                style={{ width: coverWidth }}></div>
                            <Form.Control
                                className={`${errorOccured && 'border-danger'}`}
                                as="select"
                                custom
                                required
                                onChange={onChange}
                                defaultValue={defaultValue}
                                disabled={disabled}>
                                <option value={''} disabled>{extraOption}</option>
                                {options?.map((option) => (
                                    <option value={option.value}>
                                        {option.name}
                                    </option>
                                ))}
                            </Form.Control>

                            <div
                                className={`${activated || defaultValue?.length > 0
                                    ? "textfield-label-cover-activated"
                                    : "textfield-label-cover-unactivated"
                                    } textfield-label-cover`}
                                style={{ width: "20px" }}></div>
                        </Form.Group>
                    </>
                    {errorOccured && (
                        <span style={{ color: "red", fontSize: "10px" }}>
                            {errorMessage}
                        </span>
                    )}
                </InputGroup>
            </div>
        </>
    )
}

export default SelectInput;