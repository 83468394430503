import React from "react";
import SchoolCalendarData from "./schoolCalendarData";



const SchoolCalendarPage = () => {
   return (
      <div className="h-80">

         <SchoolCalendarData />
      </div>
   );
};

export default SchoolCalendarPage;
