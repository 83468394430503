import React, { useState, useEffect, useRef } from "react";
import { Form } from 'react-bootstrap';
import InputGroup from "react-bootstrap/InputGroup";
import "./fields.scss"
const InputField = ({ labelName, placeholderName, type = 'text', value = "", onChange, errorOccured, errorMeesage, onBlur, disabled, onFocus, name, maxLength }) => {
  const [activated, setActivated] = useState(true);
  const labelRef = useRef(null);
  const inputRef = useRef(null);
  const [coverWidth, setCoverWidth] = useState(0);
  useEffect(() => {
    setCoverWidth(labelRef.current ? (labelRef.current.getBoundingClientRect().width + 9) : 0);
    if (value?.length > 0) {
      setActivated(true)
    } else {
      setActivated(false)
    }
  }, [labelRef, value]);

  function onBlurCheckInput() {
    onBlur()
    if (value.length > 0) {
      setActivated(true)
    } else {
      setActivated(false)
    }
  }

  const onBlurFn = () => {
    if (value.length > 0) {
      onBlurCheckInput()
    } else {
      setActivated(false)
    }
  }
  return (

    <>
      <div className={errorOccured ? "" : "mb-3"} style={{ width: '100%' }}>


        <InputGroup
          ref={inputRef}
          onFocus={() => { setActivated(true) }}
          onClick={() => { setActivated(true); inputRef?.current.focus() }}
        >
          <Form.Control className={`${errorOccured && 'border-danger'}  rounded`} type={type}
            // placeholder={placeholderName}
            onChange={onChange}
            value={value}
            onBlur={onBlurFn}
            disabled={disabled}
            onFocus={onFocus}
            name={name}
            maxLength={maxLength}
            style={{ zIndex: activated ? "" : 4, background: activated ? "" : "white" }} />

          <label
            className={`${activated ? "textfield-label-activated" : "textfield-label-unactivated"} textfield-label ${errorOccured && 'text-danger'}`}
            ref={labelRef}
            onClick={() => { setActivated(true); inputRef?.current.focus() }}
          >
            <strong> {labelName}</strong>
          </label>
          <div
            className={`${activated ? "textfield-label-cover-activated" : "textfield-label-cover-unactivated"} textfield-label-cover`}
            style={{ width: coverWidth }}
          />
        </InputGroup >

      </div>

      {errorOccured && (
        <div className={`${errorOccured ? "mb-3" : ""} text-danger fs-12`} style={{ height: errorMeesage == "" ? "16px" : "" }}>{errorMeesage}</div>
      )}

    </>
  )
}

export default InputField