


import React, { useContext, useEffect, useReducer, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Dropdown, Modal, Nav, Tab } from "react-bootstrap";
import 'react-datepicker/dist/react-datepicker.css';


import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import GetAllTeacherTable from "./getAllTecherTable";
import InputField from "../../components/Fields/inputFields";
import { getAllStudentListApi } from "../../../service/Student/getAllStudentList";


const GetAllTeacherPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  


  const[propertiesList,setPropertiesList] = useState([])

  const [userArr,setUserArr] = React.useState([])

  const [addTeacherModal,setAddTeacherModal] =useState(false)
  const [ isLoading,setIsLoading] = useState(false)


  const [totalTeacher,setTotalTeacher] = useState(0)


useEffect(()=>{
    onloadData()
},[])

function onloadData (){
    var data = {
        type:2,
page:1,
size:10
    }

    dispatch(getAllStudentListApi(data,(res)=>{
      setTotalTeacher(res.data.totalRecords)
        setUserArr(res.data.result)
    },()=>{}))
}



 
const pendingColumns = [
  { Header: "Teacher Id",
    Footer: "",
    accessor: "teacherId",
  
    },
  {
    Header: "Name",
    Footer: "",
    accessor: "firstName",
  },

 
  {
    Header: "Assigned Class",
    Footer: "",
    accessor: "class",
  },
  {
      Header: "Assigned Section",
      Footer: "",
      accessor: "section",
    },

  {
    Header: "Phonenumber",
    Footer: "",
    accessor: "phoneNumber",
  },
 
 
 
  
  
  
 






];

  function handleAddTeacherModal (){
setAddTeacherModal(true)
  }

  return (
    <>
      <ToastContainer />
      {/* {  toaster && <AutohideToaster action={actionMessage} message={message} handleFucntion={()=>{setToaster(false)}}/>} */}
      <div className="row d-flex justify-content-between">
        {/* <BalanceCardSlider /> */}
        <div className="row">
        <div className="col-xxl-12">
          <div className="card">
          

            <Tab.Container defaultActiveKey="Pending">
              <div className="card-header border-0 pb-2 flex-wrap">
                <h4 className="heading me-2">Teacher's List</h4>
                <button
            className="btn "
            style={{ background: "rgba(23, 90, 169, 0.78)", color: "white" }}
            onClick={handleAddTeacherModal}
          >
           Add New Teacher +
          </button>
              </div>
              <div className="card-body pt-2">
              <GetAllTeacherTable
                      column={pendingColumns}
                      rowsArr={userArr}
                  totalCount={totalTeacher}

                
                    />
              </div>
            </Tab.Container>
          </div>
        </div>
      </div>
      </div>


   



      <Modal className="fade" show={addTeacherModal} centered>
        <Modal.Header>
          <Modal.Title> Add Teacher</Modal.Title>
          <Button
            onClick={() => setAddTeacherModal(false)}
            variant=""
            className="btn-close"
          ></Button>
        </Modal.Header>
        <Modal.Body>
        <InputField labelName={"Name"} />
                
                      <InputField labelName={"Assign Class"} />
                      <InputField labelName={"Role"} />
                      <InputField labelName={"Assign Subject"} />

                      <InputField labelName={"Email"} />
                      <InputField labelName={"Phonenumber"} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setAddTeacherModal(false)}
            variant="danger light"
          >
            Close
          </Button>
          <Button
            variant="primary"
           
          >
           {isLoading ? "Adding": "Add"}
          </Button>
        </Modal.Footer>
      </Modal>

    

     
    
    </>
  );
};
export default GetAllTeacherPage;
