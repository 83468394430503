import axios from "axios";



const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const errorGet = (error) => {
  return error
}

export function serviceCallAuth(requestData) {
  // const dispatch =  useDispatch()

  return new Promise((resolve, reject) => {
    
    let url = process.env.REACT_APP_BASE_URL +requestData.url;
    const request = {
      method: requestData.method || "get",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        devicetype: "BROWSER",
        "timezone": timeZone,
      },
      url: url,
    };
    if (requestData.method === "post" || requestData.method === "put") {
      request["data"] = { ...requestData.data } || {};
    } else {
      request["params"] = { ...requestData.data } || {};
    }
    console.log(request)
    axios(request)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        console.log(err.response.status)
        reject(err);
      });
  });
}



export function serviceCall(requestData) {
  return new Promise((resolve, reject) => {
    let url = process.env.REACT_APP_BASE_URL + requestData.url;
    const request = {
      method: requestData.method || "get",

      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        devicetype: "BROWSER",
        "timezone": timeZone,
      },
      url: url ,
    };
    if (requestData.method === "post") {
      request["data"] = { ...requestData.data } || {};
    } else if (requestData.method === "put") {
      request["data"] = { ...requestData.data } || {};
    } 
    else if (requestData.method === "delete") {
      request["data"] = { ...requestData.data } || {};
    } else {
      request["params"] = { ...requestData.data } || {};
    }
    axios(request)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
       

        reject(error);
      });
  });
}

