import React from 'react'
import './skeletonTableRow.scss'
const SkeletonTableRow = ({ rows = 10, cols = 5 }) => {
    return (
        <>
            {[...Array(rows)].map((_, i) =>
                <tr key={i}>
                    {[...Array(cols)].map((_, i) =>
                        <td key={i}>
                            <div className="skeleton-custome" />
                        </td>
                    )}
                </tr>
            )}
        </>
    );
}

export default SkeletonTableRow;