import React, { useState, useEffect, useRef } from "react";
import { Form } from 'react-bootstrap';
import InputGroup from "react-bootstrap/InputGroup";

const TextArea = ({ labelName, placeholder, rows = '5', value, onChange, errorOccured, errorMessage, onBlur, onFocus, disabled, name, maxLength, type, errorMeesage }) => {
    const [activated, setActivated] = useState(false);
    const labelRef = useRef(null);
    const [coverWidth, setCoverWidth] = useState(0);
    useEffect(() => {
        setCoverWidth(labelRef.current ? (labelRef.current.getBoundingClientRect().width + 9) : 0);
        if (value?.length > 0) {
            setActivated(true)
        } else {
            setActivated(false)
        }
    }, [labelRef, value]);

    function onBlurCheckInput() {
        onBlur()
        if (value.length > 0) {
            setActivated(true)
        } else {
            setActivated(false)
        }
    }
    return (
        <>
            <div className={errorOccured ? "" : "mb-3"} style={{ width: '100%' }}>


                <InputGroup

                    onFocus={() => { setActivated(true) }}

                >
                    <Form.Control className={`${errorOccured && 'border-danger'}  rounded py-2`} type={type}
                        // placeholder={placeholderName}
                        as="textarea"
                        onChange={onChange}
                        value={value}
                        onBlur={onBlurCheckInput}
                        disabled={disabled}
                        onFocus={onFocus}
                        name={name}
                        maxLength={maxLength}
                        rows={rows}
                        style={{ height: 'auto', resize: "none", zIndex: activated ? "" : 5, background: activated ? "" : "transparent" }}
                    />
                    <label
                        className={`${activated ? "textfield-label-activated" : "textfield-label-unactivated"} textfield-label ${errorOccured && 'text-danger'}`}
                        ref={labelRef}
                        style={{ zIndex: '4' }}
                        onClick={() => { setActivated(true) }}
                    >
                        <strong>{labelName}</strong>
                    </label>
                    <div
                        className={`${activated ? "textfield-label-cover-activated" : "textfield-label-cover-unactivated"} textfield-label-cover`}
                        style={{ width: coverWidth, zIndex: '3' }}
                    />
                </InputGroup >

            </div>

            {errorOccured && (
                <div className={`${errorOccured ? "mb-3" : ""} text-danger fs-12`} style={{ height: errorMessage == "" ? "16px" : "" }}>{errorMessage}</div>
            )}

        </>
        // <div className='d-flex comment-box' >
        //     <div className='text-area-heading' >{labelName}</div>
        //     <div className='d-flex align-items-center justify-content-center' >
        //         <textarea
        //             className="form-control text-area-textarea"
        //             rows={rows}
        //             value={value}
        //             onChange={onChange}
        //             onBlur={onBlur}
        //             placeholder={placeholder}
        //             style={{ border: errorOccured ? '2px solid red' : '' }}
        //         ></textarea>

        //     </div>
        //     {errorOccured && <span style={{ color: 'red', fontSize:'11px'}}>{errorMessage}</span>}
        // </div>
    )
}

export default TextArea;