import React, { useState, useEffect } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../icons/myIcons/logo.png";
/// Scroll
//import PerfectScrollbar from "react-perfect-scrollbar";

/// Image
//import profile from "../../../images/profile/pic1.jpg";
//import avatar from "../../../images/avatar/1.jpg";
//import { Dropdown } from "react-bootstrap";
//import LogoutPage from './Logout';
//import RightSideBar from './RightSideBar';

import LogoutPage from "./Logout";

import United from "../../../images/United.png";
import avatar from "../../../images/avatar/1.jpg";
import profile from "../../../images/profile/pic1.jpg";
import { signout } from "../../../feature/authSlice";
import { useDispatch, useSelector } from "react-redux";
import PasswordField from "../../components/Fields/passwordField";
import { confirmPasswordValidation, passwordValidation } from "../../../function/validation";
import { toast, ToastContainer } from "react-toastify";

const NotificationBlog = ({ classChange }) => {
  return (
    <>
      <li>
        <div className="timeline-panel">
          <div className="media me-2">
            <img alt="images" width={50} src={avatar} />
          </div>
          <div className="media-body">
            <h6 className="mb-1">Dr sultads Send you Photo</h6>
            <small className="d-block">29 July 2022 - 02:26 PM</small>
          </div>
        </div>
      </li>
      <li>
        <div className="timeline-panel">
          <div className={`media me-2 ${classChange}`}>KG</div>
          <div className="media-body">
            <h6 className="mb-1">Resport created successfully</h6>
            <small className="d-block">29 July 2022 - 02:26 PM</small>
          </div>
        </div>
      </li>
      <li>
        <div className="timeline-panel">
          <div className={`media me-2 ${classChange}`}>
            <i className="fa fa-home" />
          </div>
          <div className="media-body">
            <h6 className="mb-1">Reminder : Treatment Time!</h6>
            <small className="d-block">29 July 2022 - 02:26 PM</small>
          </div>
        </div>
      </li>
    </>
  );
};

const Header = ({ onNote }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notifyTopRight = (e, s) => {
    if (s == "s") {
        toast.success(e, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,

        });
    } else {
        toast.error(e, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,

        });
    }
};const { userName, userId } = useSelector(state => state.auth)


  const [rightSelect, setRightSelect] = useState("Eng");
  const [changePasswordModal, setChangePasswordModal] = useState(false)
  //For fix header
  const [headerFix, setheaderFix] = useState(false);

  const [password, setPassword] = useState("")
  const [passwordError, setPasswordError] = useState(false)
  const [oldPassword, setOldPassword] = useState("")
  const [oldPasswordError, setOldPasswordError] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(false)
  const [loadingActive, setLoadingActive] = useState(false)
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setheaderFix(window.scrollY > 50);
    });
  }, []);

  //const [searchBut, setSearchBut] = useState(false);
  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
      ? filterName.filter((f) => f !== "ui")
      : filterName.includes("uc")
        ? filterName.filter((f) => f !== "uc")
        : filterName.includes("basic")
          ? filterName.filter((f) => f !== "basic")
          : filterName.includes("jquery")
            ? filterName.filter((f) => f !== "jquery")
            : filterName.includes("table")
              ? filterName.filter((f) => f !== "table")
              : filterName.includes("page")
                ? filterName.filter((f) => f !== "page")
                : filterName.includes("email")
                  ? filterName.filter((f) => f !== "email")
                  : filterName.includes("ecom")
                    ? filterName.filter((f) => f !== "ecom")
                    : filterName.includes("chart")
                      ? filterName.filter((f) => f !== "chart")
                      : filterName.includes("editor")
                        ? filterName.filter((f) => f !== "editor")
                        : filterName;



  function handleChangePassword() {
  
  }
  return (
   <>
   <ToastContainer/>
    <div className={`header ${headerFix ? "is-fixed" : ""}`}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div>
              <img
                src={logo}
                style={{ width: " 110px", paddingLeft: "16px" }}
              />
            </div>
            <div className="d-flex align-items-center" >
              {/* <i
                className="material-icons"
                style={{
                  color: "black",
                  marginRight: "15px",
                  fontSize: "25px",
                  cursor: "pointer",
                }}
              >
                notifications
              </i>
              <i
                className="material-icons"
                style={{
                  color: "black",
                  marginRight: "15px",
                  fontSize: "25px",
                  cursor: "pointer",
                }}
              >
                help
              </i> */}
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  className="light sharp i-false"
                  style={{
                    background: "white",
                    border: "white",
                    minHeight: '0px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <span className="d-flex align-items-center" style={{ marginRight: '10px', color: 'black', fontSize: '16px', fontWeight: '500' }}>  <i
                    class="material-icons"
                    style={{
                      color: "black",
                      marginRight: "5px",
                      fontSize: "25px",
                      cursor: "pointer",
                    }}
                  >
                    account_circle
                  </i>{userName}
                    <i
                      class="material-icons"
                      style={{
                        color: "black",
                        marginRight: "5px",
                        fontSize: "25px",
                        cursor: "pointer",
                      }}
                    >
                      arrow_drop_down
                    </i>

                  </span>


                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    style={{
                      display: "flex",
                    
                      color: "balck",
                    }}
                    onClick={() => {
                      setOldPassword("")
                      setPassword("")
                      setConfirmPassword("")
                      setConfirmPasswordError(false)
                      setOldPasswordError(false)
                      setPasswordError(false)
                      setChangePasswordModal(true)

                    }}
                  >
                    <div  className="d-flex align-items-center">
                    <i
                      class="material-icons"
                      style={{
                        color: "black",
                        marginRight: "6px",
                        fontSize: "25px",
                        cursor: "pointer",
                      }}
                    >
                      lock
                    </i>
                    <span>Password</span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      color: "balck",
                    }}
                    onClick={() => {
                      dispatch(signout());
                      localStorage.clear();
                    }}
                  >
               <div className="d-flex align-items-center">
               <i
                      class="material-icons"
                      style={{
                        color: "black",
                        marginRight: "6px",
                        fontSize: "25px",
                        cursor: "pointer",
                      }}
                    >
                      logout
                    </i>
                   <span> Logout User</span>
               </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {/* <span className="d-flex align-items-center" style={{marginRight:'10px',color:'black',fontSize:'16px',fontWeight:'500'}}>  {userName}      <i
                    class="material-icons"
                    style={{
                      color: "black",
                      marginRight: "5px",
                      fontSize: "25px",
                      cursor: "pointer",
                    }}
                  >
                    arrow_drop_down
                  </i></span> */}
            </div>
            {/* <div className="header-left">
				<div
					className="dashboard_bar"
					style={{ textTransform: "capitalize" }}
				  >
					{finalName.join(" ").length === 0
					  ? "Dashboard"
					  : finalName.join(" ") === "dashboard dark"
					  ? "Dashboard"
					  : finalName.join(" ")}
				</div>
            </div> */}
          </div>
        </nav>
      </div>

      <Modal className="fade" show={changePasswordModal} centered>
        <Modal.Header>
          <Modal.Title> Change Password</Modal.Title>
          <Button
            onClick={() => setChangePasswordModal(false)}
            variant=""
            className="btn-close"
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>

            <PasswordField inputValue={oldPassword}
              labelName={"Old Password"}
              errorOccured={oldPasswordError}
              errorMessage={""}
              onChange={(e) => {
                setOldPasswordError(false)
                setOldPassword(e.target.value)
              }} />
            <PasswordField inputValue={password}
              labelName={"New Password"}
              errorOccured={passwordError}
              errorMessage={""}
              onChange={(e) => {
                setPasswordError(false)
                setPassword(e.target.value)
              }} />
            <PasswordField inputValue={confirmPassword}
              labelName={"Confirm Password"}
              errorOccured={confirmPasswordError}
              errorMessage={"Password does not match "}
              onChange={(e) => {

                if (confirmPasswordValidation(password, e.target.value)) {
                  setConfirmPasswordError(false)
                } else {
                  setConfirmPasswordError(true)
                }
                setConfirmPassword(e.target.value)
              }} />


          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setChangePasswordModal(false)}
            variant="danger light"
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => { handleChangePassword() }}
          >
          { loadingActive ? "Validating":" Change Password"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div></>
  );
};

export default Header;
