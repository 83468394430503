


import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { useDispatch, useSelector } from 'react-redux';
import { setInvoiceListTab } from '../../../feature/authSlice';

import { currencyFormat } from '../../../function/commonFunction';
import Graph from '../chartComponent/graph';
import Marquee from "react-fast-marquee";

const WidgetChartIndex3 = loadable(() =>
    pMinDelay(import("./Index3/WidgetChartIndex3"), 1000)
);
const StockChartIndex3 = loadable(() =>
    pMinDelay(import("./Index3/StockChartIndex3"), 1000)
);

const ApexLine5 = loadable(() =>
    pMinDelay(import("../../components/charts/apexcharts/Line5"), 1000)
);

const announcements = [
    { time: 'Monday', event: 'Teacher meeting at 12:30' },
    { time: 'Tuesday', event: 'School meeting' },
    { time: 'Wednesday', event: 'Function discussion' },
    { time: 'Thursday', event: 'Lab Discussion' },
    { time: 'Friday', event: 'IT security meeting' },
    { time: 'Saturday', event: 'Driver enquiry' },
    { time: 'Sunday', event: 'Admission schedule' },
];

const Dashboard3 = () => {
    const { permissionArr } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [totalPayable, setTotalPayable] = useState("")
    const [totalPayout, setTotalPayout] = useState("")
    const [totalInvoiceOverdue, setTotalInvoiceOverdue] = useState("")
    const [totalPendingForApproval, setTotalPendingForApproval] = useState("")
    const [categories, setCategories] = useState([])
    const [months, setMonths] = useState([])

    const amounts = [];

    const widgetChart = [
        {
            id: 1, price: '65,123', bgcolor: 'rgba(23, 90, 169, 0.78)', nameVal: "Total Students", link: "/addInvoice", show: true, icon: "receipt_long"
        },
        { id: 2, price: '75,542', bgcolor: 'rgba(23, 90, 169)', nameVal: "Total Staff", link: "/invoiceList", show: true, icon: "task" },
        { id: 3, price: '40,742', bgcolor: 'rgb(101 168 247)', nameVal: "Absentees", link: "/", show: true, icon: "bolt" },
    ];

    const widgetChartTop = [
        {
            id: 1, price: totalPayout, bgcolor: 'rgba(23, 90, 169)', nameVal: "Total Students", link: "", show: true, icon: "school",price:"2,012"
        },
        { id: 2, price: totalPendingForApproval, bgcolor: 'rgba(23, 90, 169)', nameVal: "Total Staff", link: "", show: true, icon: "pending_actions",price:"452" },
        { id: 3, price: totalPayable, bgcolor: 'rgba(23, 90, 169)', nameVal: "Absentees", link: "/", show: true, icon: "person",price:"22" },
        { id: 4, price: totalInvoiceOverdue, bgcolor: 'red', nameVal: "Pending Leaves", link: "/", show: true, icon: "receipt_long",price:"21" },
    ];

    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="row mb-5">
                        {widgetChartTop.map((item, ind) => (
                            item.show && <div className="col-xl-3 col-lg-6 col-sm-6" key={ind} onClick={() => {
                                if (item.id === 2) {
                                    dispatch(setInvoiceListTab(2))
                                } else if (item.id === 5) {
                                    dispatch(setInvoiceListTab(1))
                                }
                                navigate(item.link)
                            }}>
                                <div className={`card`} style={{ borderRadius: '0px', border: '1px solid lightgray', flexDirection: 'row', minHeight: '80px' }}>
                                    <div className='d-flex justify-content-center align-items-center' style={{ padding: '12px', position: 'relative', bottom: '22px', width: '56px', background: item.bgcolor, left: '6px', borderRadius: '10px', maxHeight: '50px' }}>
                                        <span className="material-icons" style={{ color: 'white' }}>
                                            {item.icon}
                                        </span>
                                    </div>
                                    <div className="card-header border-0 pb-0 d-flex justify-content-start" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <div className="chart-num-days d-flex align-items-center" style={{ gap: "15px", marginBottom: '12px' }}>
                                            <span className="text-black" style={{ fontSize: '14px', fontWeight: '500' }}>{item.nameVal + " " + "-"}</span>
                                            <span className="text-black">{ item.price}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="col-xxl-12">
                        <div className="overflow-hidden bg-transparent dz-crypto-scroll shadow-none">
                            <div className="js-conveyor-example">
                                <ul className="crypto-list" id="crypto-webticker">
                                    <Marquee
                                        speed={80}
                                        loop={0}
                                        pauseOnHover={true}
                                    >
                                        {announcements.map((item, index) => (
                                            <div className="card overflow-hidden" key={index}>
                                                <div className="card-body d-flex align-items-center">
                                                    <div className="me-4">
                                                        <p className="mb-2 fs-13">{item.time}</p>
                                                        <h4 className="heading mb-0">{item.event}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </Marquee>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-8">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex align-items-center">
                                        <i className="fa fa-person scale-2 text-warning"></i>
                                        <h4 className="heading ms-3 mb-0" style={{ color: 'rgba(23, 90, 169, 0.78)' }}> Details</h4>
                                    </div>
                                </div>
                                <div className="card-body pt-2">
                                    <ApexLine5/>
                                    {/* <Graph category={categories} month={months} amount={amounts} /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4" style={{ zoom: '0.9' }}>
                    <div className="card">
                        <div className="card-header">
                            <h4 className="heading mb-0" style={{ color: "rgba(23, 90, 169, 0.78)" }}>Quick Links</h4>
                        </div>
                        <div className="card-body pt-0">
                            <div className='row' style={{ padding: '15px', gap: '35px', display: 'flex', justifyContent: 'space-around' }}>
                                {widgetChart.map((item, ind) => (
                                    item.show && <div className='col-3 d-flex flex-column align-items-center' onClick={() => { navigate(item.link) }} style={{ cursor: "pointer", gap: '10px', padding: '6px', borderRadius: '12px', background: '#d3d3d380', color: 'rgba(23, 90, 169, 0.78)' }}>
                                        <span className="material-icons">
                                            {item.icon}
                                        </span>
                                        <span style={{ textAlign: 'center' }}>{item.nameVal}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Dashboard3;
