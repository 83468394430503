export const apiConstants = {

    AUTH:{
        VALIDATE_SCHOOL:"/validateSchoolId",
        ADMIN_LOGIN:"/adminLogin"
    }
,

STUDENT:{
    GET_ALL_STUDENT:"/getUserByType",
    REGISTER_STUDENT:"/registerStudent"
},
CALENDAR:{
GET_EVENT:"/getEvents",
ADD_EVENT:"/addEvent",
DELETE_EVENT:"/deleteEvent",
EDIT_EVENT:"/updateEvent"
}

}
