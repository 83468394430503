import React from 'react';
import BalanceCardSlider from './Dashboard/BalanceCardSlider';
import Dashboard3 from './Dashboard3';






const Home = () => {
	

  
	return(
		<>
		<div className="row d-flex " style={{gap:'10px',marginTop:'15px'}}>
			<Dashboard3/>
        {/* <BalanceCardSlider /> */}
   
</div>
		</>
	)
}
export default Home;