import React, { useEffect, useMemo, useState } from "react";
import { useTable, useSortBy } from "react-table";

import PaginationAndSearching from "../../components/Pagination Component/paginationAndSearching";
import SkeletonTableRow from "../../components/SkeltonTable/skeletonTable";
import { useDispatch, useSelector } from "react-redux";

//import './table.css';

export const GetAllTeacherTable = ({
  column,
  rowsArr,
  handleVerify,
  handleSearchPagination,
  searchValuePagination,
  pageSizePagination,
  handleChangeRowsPerPagePagination,
  totalCountPagination,
  handleOnChangePagination,
  showLoading,
  handleDeleteById,
  handleTabExpanded,
  userArr,
  handleSeeVendor,
  totalCount,
  handleActionPending
}) => {
  const {permissionArr} = useSelector(state =>state.auth)
  const dispatch = useDispatch()
  const columns = useMemo(() => column, []);
  const data = useMemo(() => rowsArr, [rowsArr]);
  const userData = useMemo(() => userArr, [userArr]);

  const tableInstance = useTable({ columns, data }, useSortBy);
useEffect(()=>{},[userArr])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    //footerGroups,
    rows,
    prepareRow,
  } = tableInstance;

 



  return (
    <>

      <div className="card">
        <div className="card-header">
          <h3></h3>
          {/* <div className='pagination-search-container'>
                    <div className="service-input-group input-group">
                        <span className="input-group-text search-input-icon">

                            <i className="bi bi-search" style={{ fontSize: '16px' }}></i>
                        </span>
                        <input
                            type="text"
                            className="form-control search-input"
                            id="val-username1"
                            placeholder="Search..."
                            name="username"
                            value={searchValuePagination}
                            onChange={handleSearchPagination}
                        />
                        </div>
                    </div> */}
        </div>
        <div className="card-body">
        <h4> Total Teachers : &nbsp; {totalCount}</h4> <div className="d-flex justify-content-end">
            <div className="service-input-group input-group" style={{width:"220px"}}>
                        <span className="input-group-text search-input-icon">

                            <i className="bi bi-search" style={{ fontSize: '16px' }}></i>
                        </span>
                        <input
                            type="text"
                            className="form-control search-input"
                            id="val-username1"
                            placeholder="Search..."
                            name="username"
                            value={searchValuePagination}
                            onChange={handleSearchPagination}
                        />
                    </div>
            </div>
          <div className="table-responsive">
         
            <div className="dataTables_wrapper">
              <table {...getTableProps()} className="table dataTable display" >
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          style={{ color: "rgba(23, 90, 169, 0.78)" }}
                        >
                          {column.render("Header")}
                          <span className="ml-1">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <i
                                  className="fa fa-arrow-down ms-2 fs-14"
                                  style={{ opacity: "0.7" }}
                                />
                              ) : (
                                <i
                                  className="fa fa-arrow-up ms-2 fs-14"
                                  style={{ opacity: "0.7" }}
                                />
                              )
                            ) : (
                              <i
                                className="fa fa-sort ms-2 fs-14"
                                style={{ opacity: "0.3" }}
                              />
                            )}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
             
                <tbody {...getTableBodyProps()}>
                  {rows.map((row, rowIndex) => {
                    prepareRow(row);
                    return (

                      <>
                       <tr {...row.getRowProps()}>
                        {row.cells.map((cell, cellIndex) => {
                     

                          return (
                            <td
                              {...cell.getCellProps()}
                              style={{ color: "black" ,whiteSpace:'normal' }}
                            >
                         { cell.render("Cell") }
                           
                            </td>
                          );
                        })}

                      </tr>
                   
                      </>
                     

                      
                      
                    );
                  })}
                </tbody>{" "}
             {showLoading &&   <SkeletonTableRow cols={4} row ={4}/>}
                {/* This is only for footer if u require */}
              </table>

              {rows.length === 0 && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  No record found
                </div>
              )}

           

            </div>
          </div>
          <PaginationAndSearching
                  handleSearch={handleSearchPagination}
                  searchValue={searchValuePagination}
                  defaultValue={pageSizePagination}
                  handleChangeRowsPerPage={handleChangeRowsPerPagePagination}
                  totalCount={totalCountPagination}
                  onChange={(e,p)=>{
                    handleOnChangePagination(e,p)
                    // setCheckedRows([])
                  }}
                />
        </div>
      </div>
    </>
  );
};
export default GetAllTeacherTable;
