import { lazy, Suspense, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

/// Components
import Index from "./jsx";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
// action
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";


const SignUp = lazy(() => import('./jsx/pages/Registration'));
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const Login = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
  });
});




function App(props) {
  
// useEffect(() => {
//   window.onkeydown = function(key){if(key.ctrlKey == true){key.preventDefault()}};
//   const handleContextmenu = e => {
//       e.preventDefault()
//   }
//   document.addEventListener('contextmenu', handleContextmenu)
//   return function cleanup() {
//       document.removeEventListener('contextmenu', handleContextmenu)
//   }
// }, [ ])
  return (
		
		  <Index />
	
	);
};




export default App

