import { createSlice } from '@reduxjs/toolkit'

const initialStateValue = {
  authorized: false,
  userName:"",
  userId:""
  

}

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialStateValue,
  reducers: {
    authenticate: (state) => {
      state.authorized = true
    },
    signout: (state) => {
      state.authorized = false
    },

    setUserDetails:(state,action)=>{
   
      state.userName = action.payload.firstName
      // state.userId = action.payload.result.UserId
    },
    
   

  },
})

export const { authenticate, signout,setUserDetails ,setInvoiceListTab} = authSlice.actions

export default authSlice.reducer
