import React, { useState, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
export default function PasswordField({
  labelName,
  onChange,
  inputValue,
  errorOccured,
  errorMessage,
  handleOnBlur,
  onFocus,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [activated, setActivated] = useState(false);
  const labelRef = useRef(null);
  const [coverWidth, setCoverWidth] = useState(0);
  useEffect(() => {
    setCoverWidth(
      labelRef.current ? labelRef.current.getBoundingClientRect().width + 9 : 0
    );
  }, [labelRef, inputValue]);

  function onBlurCheckInput() {
    handleOnBlur();
    if (inputValue.length > 0) {
      setActivated(true);
    } else {
      setActivated(false);
    }
  }
  function handleShowPassword() {
    setShowPassword(!showPassword);
  }

  const onBlurFn = () => {
    if (inputValue.length > 0) {
      onBlurCheckInput()
    } 
    else {
      setActivated(false)
    }
  }

  return (
    <div className="mb-3">
      <div className="input-group" style={{ transform: "none" }}>
        <InputGroup
          className={`${errorOccured ? "":"mb-3"}`}
          onFocus={() => {
            setActivated(true);
          }}>
          <Form.Control
            className={`passwordFieldLeft ${errorOccured && 'border border-danger border-end-0'}`}
            // placeholder={placeholderName}
            type={showPassword ? "text" : "password"}
            value={inputValue}
            onChange={onChange}
            onBlur={onBlurFn}
            onFocus={onFocus}
          />
          <label
            className={`${
              activated
                ? "textfield-label-activated"
                : "textfield-label-unactivated"
            } textfield-label ${errorOccured && 'text-danger'}`}
            ref={labelRef}
            onClick={() => {
              setActivated(true);
            }}>
            <strong> {labelName}</strong>
          </label>
          <div
            className={`${
              activated
                ? "textfield-label-cover-activated"
                : "textfield-label-cover-unactivated"
            } textfield-label-cover`}
            style={{ width: coverWidth }}
          />
          <InputGroup.Text
            className={`passwordFieldRight ${errorOccured && 'border border-danger border-start-0'}`}
            onClick={handleShowPassword}>
            {showPassword ? (
              <i className="password-eye bi bi-eye-fill"></i>
            ) : (
              <i className="password-eye bi bi-eye-slash-fill"></i>
            )}
          </InputGroup.Text>
        </InputGroup>
      </div>
      {errorOccured && errorMessage=="" && <div className={`${errorOccured ? "mb-3":""} text-danger fs-12`}>
        <div>Password should have atleast 12 characters</div>
        <div>Password should have atleast 1 Uppercase, 1 lowercase, 1 Numeric and 1 special character</div>
        </div>}
        {errorOccured && errorMessage!="" && <div className={`${errorOccured ? "mb-3":""} text-danger fs-12`}>
      {errorMessage}
        </div>}
    </div>
  );
}
